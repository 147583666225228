import axios from "axios";
// import Vue from "vue";
import { PTD } from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";

const documentActions = documentActionsFactory(PTD);
export default {
  namespaced: true,
  state: {
    selected: {},
    selectedWares: [],
    // editableWare: { id: null, index: null },
    // selectedId: null,
    requests: [],
    control: {},
  },
  mutations: {
    ...documentMutations,
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    // UPDATE_DECLARATION_PAYMENT(state, items) {
    //   if (items.length === state.selected.declaration_payments.length) {
    //     state.selected.declaration_payments.splice(
    //       0,
    //       state.selected.declaration_payments.length,
    //       ...items
    //     );
    //   } else {
    //     items.forEach((item) => {
    //       const { id } = item;
    //       const current = state.selected.declaration_payments.find(
    //         (i) => i.id === id
    //       );
    //       const index = state.selected.declaration_payments.indexOf(current);
    //       state.selected.declaration_payments.splice(index, 1, item);
    //     });
    //   }
    // },
    // ADD_PAYMENTS_ITEM(state, { item, nextItemPosition }) {
    //   state.selected.declaration_payments.splice(nextItemPosition, 0, item);
    // },
    // UPDATE_DECLARATION_PAYMENTS(state, payload) {
    //   state.selected.declaration_payments = payload;
    // },
    // SET_CONTROL(state, value) {
    //   state.control = { ...value };
    // },
    ADD_REQUEST(state, promise) {
      state.requests.push(promise);
    },
    REMOVE_REQUEST(state, promise = null) {
      if (promise) {
        const idx = state.requests.indexOf(promise);
        state.requests.splice(idx, 1);
      } else {
        state.requests = [];
      }
    },    
  },
  actions: {
    ...documentActions,
    downloadPdf(_, id) {
      return axios.get(`${PTD}declaration/pdf?id=${id}`, {
        responseType: "blob",
      });
    },
    copyDocument({rootState}, {id}) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${PTD}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&userId=${uid}`
      ;
      return axios.post(url);
    },
    createSo({state, rootState}) {
      const declarationId = state.selected.id;
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${PTD}declaration/createSo?declarationId=${declarationId}&userId=${userId}`)
    },
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getRequests: s => s.requests,
    getControl: (s) => s.control,
  },
};
