<template>
  <v-menu
    offset-y
    max-width="500"
    max-height="500"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="mx-1"
        fab
        dark
        small
        color="white"
        v-bind="attrs"
        @click="onClick"
        v-on="on"
      >
        <v-icon color="#5CB7B1">
          mdi-bell
        </v-icon>
        <v-badge
          v-if="total > 0"
          offset-y="-5"
          color="#5CB7B1"
          bordered
          :content="total"
          dark
        />
      </v-btn>
    </template>
    <v-list>
      <template v-if="invites.length">
        <v-list-item
          v-for="(invite, idx) in invites"
          :key="'invite'+idx"
          class="py-1"
        >
          <div>
            <div class="text-body-2 mb-1">
              <strong>{{ invite.title }}</strong>
            </div>
            <div class="text-body-2">
              {{ invite.message }}
            </div>
            <div
              class="text-right"
              @click.stop
            >
              <v-btn
                text
                color="red"
                @click="skiptInvite(invite)"
              >
                Отменить
              </v-btn>
              <v-btn
                text
                color="#5CB7B1"
                @click="acceptInvite(invite)"
              >
                Принимаю
              </v-btn>
            </div>
          </div>
        </v-list-item>
        <v-divider
          v-if="!invites.length"
        />
      </template>
      <div
        v-else
        style="width:500px;"
        class="text-center"
      >
        <div class="text-body-2 mb-1 grey--text px-10">
          <v-icon color="grey">
            mdi-eye-off-outline
          </v-icon>Приглашения отсутсвуют
        </div>
      </div>

      <template v-if="notifications.length">
        <v-divider />
        <v-list-item
          v-for="(notification, idx) in notifications"
          :key="'notification-'+idx"
          :class="notification.viewed ? 'white':'blue lighten-5'"
        >
          <v-list-item-content>
            <v-list-item-title>{{ notification.divisionName }}</v-list-item-title>
            <v-list-item-subtitle>{{ notification.createdAt }} </v-list-item-subtitle>
            <span class="dark--text">{{ notification.message }}</span>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <document-statuses />
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import {AUTH} from '@/http-routes'
import { notification, docStatusesNotification } from "@/helpers/socket";
import moment from "moment";
import DocumentStatuses from "./user-notifications/document-statuses.vue";

export default {
  components: { DocumentStatuses },
  data:() => ({
    notificationsSocket:null,
    notifications:[]
  }),
  computed: {
    ...mapGetters({
      invites: 'auth/getInvites',
      user: "auth/getUser",
      notificationsAmount: "notification/getNotViewedAmount",
      notificationGroups:"notification/getGroups",
      documentStatuses: "notification/getDocumentsStatuses",
    }),
    total(){
      return this.notificationsAmount + this.invites.length + this.documentStatuses.length
    }
  },
  watch:{
    notificationGroups:{
      handler(nv){
        this.notifications = this.mapToList(nv)
      },
      deep:true,
    }
  },
  created() {
    this.notificationsSocket = notification.call(this)
    this.notificationsSocket.subscribe()
    this.$store.dispatch("notification/fetchNewNotificationsAmount")
    // подписаться на событие declarationChangeStatus
    this.docStatusesSocket = docStatusesNotification.call(this);
    this.docStatusesSocket.subscribe();
  },
  beforeDestroy() {
    this.notificationsSocket.unsubscribe();
    this.docStatusesSocket.unsubscribe();
  },
  methods: {
    onClick(){
      this.$store.dispatch('auth/loadInvites')
      this.fetchNotifications().then(() => {
        setTimeout(() => {
          this.$store.dispatch('notification/setAllViewed')
        })
      })
    },
    mapToList(groups){
      const list = []
      Object.keys(groups).forEach(group => {
        groups[group].forEach(division => {
          list.push(...division.notifications)
        })
      })
      return list.map(item => ({
        ...item,
        createdAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')
      }))
    },
    fetchNotifications(){
      return this.$store.dispatch('notification/fetchNewNotifications')
    },
    acceptInvite(inv){
      axios
        .post(`${AUTH}user/acceptInvite?userId=${inv.details.user_id}&divisionId=${inv.details.division_id}`)
        .then(() =>{
          this.$store.dispatch('auth/loadInvites', this.user)
          this.$store.dispatch('auth/loadDivisions')
        })
    },
    async skiptInvite(inv){
      await axios
        .post(`${AUTH}user/abortInvite?userId=${inv.details.user_id}&divisionId=${inv.details.division_id}`)
        .then(() =>{
          this.$store.dispatch('auth/loadInvites', this.user)
          this.$store.dispatch('auth/loadDivisions')
        })

    }
  }
}
</script>
