<template>
  <ware-card
    :id="block_id"
    :index="index"
    :sort-index="item.sort_index"
    selector-class="dt-goods-item"
    @outside="triggerOnFocus"
    @toggle="onToggle"
    @toggle-tick="onToggleTick"
  >
    <template #title>
      <ware-title :item="item" />
    </template>
    <template #menu>
      <v-list>
        <v-list-item
          class="wares-item-menu-delete"
          @click="deleteItem"
        >
          Удалить
        </v-list-item>
        <v-list-item
          class="wares-item-menu-copy"
          @click="copyItem"
        >
          Копировать
        </v-list-item>
      </v-list>
    </template>
    <template #body>
      <div :class="{'blocked-box' : disabledView}">
        <code-block
          :index="index"
          :visited.sync="fields_been_visited"
          :has-changes.sync="hasChanges"
          :ware-details="wareDetails"
          :show-quantity="showWareDetailQuantity"
          :control="waresControlPath"
          :current-state="info"
          @update="updateInfoInComponent"
        />
        <v-row class="statistics-box rounded-0 mx-0 mb-4 rounded-b-lg">
          <v-col cols="7">
            <ware-details-table
              :control="waresControlPath"
              :index="index"
              :ware-details="wareDetails"
            />
            <measure-units
              :index="index"
              :info="info"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :control="waresControlPath"
              :current-state="info"
              @update="updateInfoInComponent"
            />
            <parameters
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :info="info"
              :control="waresControlPath"
              @update="updateInfoInComponent"
            />
          </v-col>
          <v-divider vertical />
          <payments             
            ref="paymentsComponent"
            :info="info"
            :index="index"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            :control="waresControlPath"
            :ware-details="wareDetails"
            @update="updateInfoInComponent"
          />
          <v-col cols="12">
            <preceding-document-list
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :control="waresControlPath"
              :info="info"
              @update="updateInfoInComponent"
            />
          </v-col>
        </v-row>
      </div>
      <component
        :is="activeComponent"
        :show.sync="showActiveComponent"
        :params="activeComponentParams"
      />
    </template>
  </ware-card>
</template>

<script>
import CodeBlock from './code.vue'
import MeasureUnits from './measure-units.vue'
import Parameters from "./parameters.vue"
import PrecedingDocumentList from './preceding-documents/document-list.vue'
import Payments from './payments/payments.vue'
import WareDetailsTable from './ware-details-table.vue'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {wares as onWares} from '@/events/statistics/control'
import {
  forceUpdateWareDetail,
  wareDetailModified,
  addPromise
} from "@/events/statistics";
import WareCard from "@/components/documents/ware/ware-card.vue";
import WareTitle from "@/components/documents/ware/ware-title.vue";
import {
  addPromiseInjector,
  isDirtyInjector,
  patchStateInjector,
  readyToUpdateInjector,
  resetTriggersInjector, showDynamicComponentInjector,
  uploadDataInjector
} from "@/helpers/injectors";
import {getFirstValueFromMap, removeFirstFromMap, wareHighlight} from "@/helpers/control";
import {mapGetters} from "vuex";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {eventBus} from "@/bus"

export default {
  components: {
    WareTitle,
    WareCard,
    CodeBlock,
    MeasureUnits,
    Parameters,
    PrecedingDocumentList,
    Payments, 
    WareDetailsTable
  },
  mixins: [blockAutoUpdate],
  provide() {
    return {
      [patchStateInjector]: this.updateInfoInComponent,
      [readyToUpdateInjector]: this.readyToUpdate,
      [addPromiseInjector]: this.addPromise,
      [uploadDataInjector]: this.uploadData,
      [resetTriggersInjector]: this.resetTriggers,
      [isDirtyInjector]: this.isDirty,
      [showDynamicComponentInjector]: this.showDynamicComponent
    }
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    activeComponent:null,
    showActiveComponent: null,
    activeComponentParams:null,
    show: false,
    block_id: null,
    is_goods_item: true,
    info: {},
    wareDetails: [],
    promises: [],
    promisesWares: [],
    onWares: null,
    waresControlPath: {
      path: null
    },
    addMeasureLetterInput: null
  }),
  computed: {
    ...mapGetters({
      disabledView: "dt/getVisibility",
    }),
    showWareDetailQuantity() {
      return !!(this.info.add_measure_unit_letter || document.activeElement === this.addMeasureLetterInput) || false
    }
  },
  watch: {
    item: {
      handler(info) {
        this.info = info;
        this.setBlockId()
      },
      immediate: true,
      deep: true,
    }
  },
  created() {
    wareDetailModified.subscribe(this.checkAndSetVisited);
    addPromise.subscribe(this.onComponentPromise)
    eventBus.$on("updateWareDetails", this.updateWareDetailsFromModal);
    this.onWares = {...onWares} // Делаем локальную копию чтобы можно было менять имя события
    this.onWares.name = `${this.onWares.name}-${this.index + 1}`
    this.onWares.subscribe(this.onControl)
  },
  beforeDestroy() {
    this.onWares.unsubscribe()
    wareDetailModified.unsubscribe();
    addPromise.unsubscribe();
    eventBus.$off("updateWareDetails", this.fetchWareDetails);
  },
  methods: {
    saveChanges(){
      if (!this.isDirty()) return Promise.resolve();
      return this.uploadData()
        .catch((err) => {this.$error("Ошибка сохранения"); throw err})
        .then(() => this.resetTriggers())
    },
    showDynamicComponent({params, component}){
      this.saveChanges()
        .then(() => {
          this.activeComponentParams = params
          this.activeComponent = component
          this.showActiveComponent = true
        })
    },
    onControl({path}) {
      const index = getFirstValueFromMap(path)
      const selector = ".dt-goods-item:nth-of-type(" + index + ")";
      const el = document.querySelector(selector);
      this.$scrollTo(el, 200, {
        force: true,
        onDone: () => {
          removeFirstFromMap(path)
          wareHighlight.call(this, {el, path})
        },
      });
    },
    readyToUpdate() {
      this.hasChanges = true
      this.fields_been_visited = true
    },
    setBlockId() {
      this.block_id = `dt-goods-item-${this.index}`;
    },
    markAsEditable() {
      if (!this.info.id) return
      this.$store.commit("dt/SET_EDITABLE_WARE", {
        id: this.info.id,
        index: this.index,
      });
    },
    onToggle(value) {
      if (value) this.fetchWareDetails();
      value ? this.markAsEditable() : this.destroyEventListeners()
    },
    onToggleTick(value) {
      if (value) {
        this.setEventListeners()
        this.$refs.paymentsComponent.preloadAvailablePaymentsOptions()
      } else {
        this.unsetAddMeasureField()
      }
    },
    getClickEvent() {
      return new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
    },
    addPromise(promise) {
      this.promises.push(promise)
    },
    onComponentPromise({id, promise, type = "block"}) {
      if (id === this.info.id) {
        type === "ware" ? this.promisesWares.push(promise) : this.promises.push(promise)
      }
    },
    checkAndSetVisited({index, action}) {
      if (this.index === index) {
        this.updateWareDetails(action);
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
        if (["add", "delete"].includes(action)) {
          this.updateEventListeners();
        }
      }
    },
    updateWareDetails(action) {
      if (["add", "delete"].includes(action)) {
        this.fetchWareDetails();
      }
    },
    updateWareDetailsFromModal(data) {
      if (data.wareId === this.item.id) {
        this.wareDetails = data.items
      }
    },
    unsetAddMeasureField() {
      this.addMeasureLetterInput = null
    },
    setAddMeasureField() {
      const params = this.$refs.params.$el
      this.addMeasureLetterInput = params?.querySelector('.add_measure_unit_digit input') || null
    },
    fetchWareDetails() {
      this.$store
        .dispatch("dt/fetchWareDetails", {wareId: this.item.id})
        .then((res) => {
          this.wareDetails = [...res.data];
        });
    },
    updateInfoInComponent(data) {
      this.info = {
        ...this.info,
        ...data,
      };
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        this.promises = []
        forceUpdateWareDetail.trigger({id: this.item.id});
        return Promise.all(this.promisesWares)
      }).then(() => {
        this.promisesWares = []
        const value = convertEmptyStringsToNull(this.info);
        return this.$store.dispatch("dt/updateGoodsItem", value);
      })
    },
    deleteItem() {
      const {id, sort_index} = this.item;
      const index = this.index;
      this.$store.dispatch("dt/deleteGoodsItem", {
        id,
        sort_index,
        index,
      }).then(() => this.$info("Товар удален"))
        .catch(() => this.$error("Ошибка удаления товара"));
    },
    copyItem() {
      const {id} = this.item
      this.$store.dispatch('dt/copyWare', {id}).then(() => {
        this.$snackbar({text: 'Товар скопирован', top: false, right: false})
      }).catch((err) => this.$error(`Не удалось скопировать товар. ${err.response.data}`)
      )
    }
  },
};
</script>
