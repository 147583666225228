<template>
  <v-col
    cols="12"
    class="prevent-trigger-update"
  >
    <v-row>
      <v-col
        class="d-flex justify-start"
      >
        <v-text-field
          v-model="searchValue"
          hide-details="auto"
          background-color="#EDEDED"
          class="ba-0 rounded-l rounded-r"
          filled
          rounded
          full-width
          placeholder="Поиск по номеру"
          clearable
          :readonly="loading"
          @click:clear="() => (searchValue = '')"
        />
        <v-btn
          dark
          width="60px"
          height="56px"
          min-width="30px"
          x-large
          class="elevation-0 ml-3"
          @click="fetchRegistrations"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          dark
          color="teal lighten-2"
          width="60px"
          height="56px"
          min-width="30px"
          x-large
          class="elevation-0 ml-3"
          @click="openInOriginalSite"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="`header-${header.value}`"
          >
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in registrations"
          :key="'type-item-' + idx"
        >
          <td
            v-for="header in headers"
            :key="`type-item-${idx}-${header.value}`"
          >
            {{
              item[header.value]
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>

import moment from "moment";
export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    searchValue: "",
    registrations: [],
    headers: [
      {
        name: 'Получатель',
        value: 'firmget_name'
      },
      {
        name: 'Изготовитель',
        value: 'firmmade_name'
      },
      {
        name: 'Наименование',
        value: 'name_prod'
      },
      {
        name: 'Статус',
        value: 'status_name'
      },
    ]
  }),
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true
    }
  },
  created() {
    this.fetchRegistrations()
  },
  methods: {
    openInOriginalSite(){
      const date = moment().format('YYYY-MM-DD')
      window.open(`https://nsi.eaeunion.org/portal/1995?NUMB_DOC=${this.searchValue}&date=${date}`, '_blank')
    },
    keysLowerCase(registration) {
      const result = {}
      Object.keys(registration).forEach(key => {
        result[key.toLowerCase()] = registration[key]
      })
      return result
    },
    fetchRegistrations() {
      const payload = {
        date: moment().format('YYYY-MM-DD'),
        filter: [
          {
            code: "searchText",
            value: this.searchValue,
            conditionType: "like"
          }
        ]
      }
      this.$store.dispatch('catalogs/fetchGovernmentRegistrations', payload).then(res => {
        this.registrations = res.data
          .map(registration => this.keysLowerCase(registration.data))
          .map(registration => {
            return {
              ...registration,
              status_name:registration.status.name
            }
          })
      }).catch(() => this.$error())
    }
  }
}
</script>