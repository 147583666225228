<template>
  <div
    v-if="statusesNotifications.length"
    class="white"
  >
    <v-divider />
    <div class="d-flex align-center justify-space-between px-4 py-2">
      <!-- <v-btn
        min-width="32px"
        icon
        tabindex="-1"
        @click="collapsed = !collapsed"
      >
        <v-icon>
          {{ collapsed ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn> -->
      <div class="text--disabled">
        Статус документов
        <v-badge
          v-if="statusesNotifications.length > 0"
          offset-y="-7"
          color="blue-grey lighten-1"
          bordered
          :content="statusesNotifications.length"
          dark
        />
      </div>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            tabindex="-1"
            size="32px"
            color="#5cb7b1"
            v-on="on"
            @click.stop="deleteAll"
          >
            <!-- <v-icon>mdi-open-in-new</v-icon> -->
            mdi-arrow-top-right-bold-box
          </v-icon>
        </template>
        <span>Пропустить</span>
      </v-tooltip>
    </div>
    <template v-if="collapsed">
      <v-card
        class="list"
        max-height="370px"
      >
        <v-list-item
          v-for="(notification, idx) in statusesNotifications"
          :key="'notification-' + idx"
          class="px-2 pb-1"
        >
          <v-sheet
            :elevation="2"
            width="100%"
            class="rounded blue-grey lighten-5 cursor-pointer"
            @click="openNotification(notification.id, notification.type)"
          >
            <v-row
              no-gutters
              class="px-4 py-2"
              align="center"
            >
              <v-col cols="1">
                <v-icon :color="iconsForStatuses(notification.status).color">
                  {{ iconsForStatuses(notification.status).icon }}
                </v-icon>
              </v-col>
              <v-col cols="2">
                {{ notification.ru_type }}
              </v-col>
              <v-col cols="4">
                {{ notification.out_number }}
              </v-col>
              <v-col cols="4">
                {{ notification.status }}
              </v-col>
              <v-col cols="1">
                <v-btn
                  small
                  icon
                  color="blue-grey lighten-3"
                  @click.stop="deleteNotification(notification.id)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-list-item>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";

export default {
  data: () => ({
    collapsed: true,
    statusesNotifications: [],
  }),
  computed: {
    ...mapGetters({
      documentStatuses: "notification/getDocumentsStatuses",
    }),
  },
  watch: {
    documentStatuses: {
      handler(nv) {
        this.statusesNotifications = this.mapToList(nv);
      },
      deep: true,
    },
  },
  created() {
    this.statusesNotifications = this.mapToList(
      cloneDeep(this.documentStatuses)
    );
  },
  methods: {
    openNotification(id, type) {
      this.$store.commit("journal/SET_DOCUMENT_TYPE", type);
      this.$store.commit("journal/SET_PAGINATION", { page: 1 });
      this.$store.commit("journal/SET_SEARCH", {
        searchBy: "outNumberCondition", // подставляю selectedPlaceName:"Hомер"
        searchValue: id,
      });
      this.$store.dispatch("journal/fetchItems");
      if (this.$route.path !== "/journal") {
        const route = this.$router.resolve({
          path: `/journal`
        });
        window.open(route.href, "_blank");
      }
      this.deleteNotification(id);
    },
    deleteNotification(id) {
      this.statusesNotifications = this.statusesNotifications.filter(
        (item) => item.id !== id
      );
      this.$store.commit(
        "notification/SET_DOCUMENT_STATUSES",
        this.statusesNotifications
      );
    },
    deleteAll() {
      this.$store.commit("notification/SET_DOCUMENT_STATUSES", []);
      this.collapsed = false;
    },
    mapToList(groups) {
      return groups.map((item) => ({
        ...item,
        created: moment(item.created).format("DD.MM.YYYY HH:mm:ss"),
      }));
    },
    iconsForStatuses(status) {
      if (
        ["принято в ИСТО", "зарегистрировано", "разрешение"].includes(status)
      ) {
        return {
          icon: "mdi-check-circle",
          color: "#78A55A",
        };
      } else if (["отказ в принятии"].includes(status)) {
        return {
          icon: "mdi-close-circle",
          color: "#EB3223",
        };
      } else if (["требование (БД 44)"].includes(status)) {
        return {
          icon: "mdi-file-document-alert",
          color: "#EB3223",
        };
      } else {
        return {
          icon: "",
          color: "",
        };
      }
    },
  },
};
</script>

<style scoped>
/* полоса прокрутки (скроллбар) */
.list::-webkit-scrollbar {
  width: 10px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
  background-color: #F8F9FB;
}
/* ползунок скроллбара */
.list::-webkit-scrollbar-thumb {
  background-color: #a5b0afce;
  border-radius: 5px;
}
.list {
  overflow-y: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .list::-webkit-scrollbar {
  width: 0;
  height: 0;
} */
</style>
