<template>
  <v-col
    sm="12"
    lg="4"
    :class="{
      'px-0': mobile
    }"
  >
    <v-row>
      <v-col cols="6">
        <v-select
          color="teal"
          item-color="teal"
          :value="params.declarationKindCodeCondition"
          :items="kindCodeConditions"
          hide-details="auto"
          background-color="white"
          class="ba-0 rounded"
          filled
          rounded
          label="Тип"
          @change="setDeclarationKindCodeCondition"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          color="teal"
          item-color="teal"
          :value="params.customsProcedureCondition"
          :items="customsProcedureConditions"
          hide-details="auto"
          background-color="white"
          class="ba-0 rounded"
          filled
          rounded
          label="Процедура"
          @change="setCustomsProcedureCondition"
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'

const TYPE = 'kdt'

export default {
  data:() => ({
    params:{
      declarationKindCodeCondition:null,
      customsProcedureCondition:null
    },
    kindCodeConditions:[
      {
        text:'ВСЕ ТИПЫ',
        value:null
      },
      {
        text:'ИМ',
        value:'ИМ'
      },
      {
        text:'ЭК',
        value:'ЭК'
      },
    ],
  }),  
  computed:{
    ...mapGetters({
      typeDependentParams:'journal/getTypeDependentParams',
      customsProcedures:"catalogs/getNsiCustomsProcedures"
    }),
    customsProcedureConditions(){
      const selectAllOption = {
        text:'ВСЕ ПРОЦЕДУРЫ',
        value:null
      }
      const procedures = this.customsProcedures.map(procedure => {
        return {
          text:procedure.text,
          value:procedure.code
        }
      })
      return [selectAllOption, ...procedures]
    },
    mobile(){
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch:{
    typeDependentParams:{
      handler(){
        this.setValues()
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    setValues(){
      const available = this.typeDependentParams.filter(i => i.type === TYPE) 
      available.forEach(item => {
        const {name, value} = item
        if(name in this.params){
          this.params[name] = value
        }
      })
    },
    setCustomsProcedureCondition(value){
      this.setCondition({value, name:'customsProcedureCondition'})
    },
    setDeclarationKindCodeCondition(value){
      this.setCondition({value, name:'declarationKindCodeCondition'})
    },
    setCondition({name, value}){
      this.$store.commit('journal/SET_TYPE_DEPENDENT_PARAM', {
        type:TYPE,
        name,
        value
      })
      this.$store.dispatch('journal/fetchItems')
    }
  }
}
</script>