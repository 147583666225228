import {mapGetters} from "vuex";

export default {
  computed:{
    ...mapGetters({
      documentsInBuffer:'buffer/getDocuments',
      waresInBuffer:'buffer/getWares',
      selectedDocumentType:'journal/getDocumentType'
    }),
    bufferWaresIds(){
      return this.waresInBuffer.map(i => i.id)
    }
  },
  methods:{
    bufferClearSelection(){
      window.getSelection().empty();
    },
    bufferSelectNotification(){
      return this.$snackbar({
        text:'Невозможно выбрать элемент',
        color:'orange',
        top:false
      })
    },
    removeEmptyValues(list){
      return list.filter(i => ![null, ''].includes(i))
    },
    bufferWare(item, payload, event){
      if(!event.shiftKey) return false
      this.bufferClearSelection()
      const {id, title, tn_ved, add_tn_ved, description_combined} = item
      if(!id){
        this.bufferSelectNotification()
      }
      if(this.bufferWaresIds.includes(id)){
        return this.$store.commit('buffer/REMOVE_WARE', id)
      }
      this.$store.commit('buffer/ADD_WARE',{
        id,
        module:this.selectedDocumentType,
        title: this.removeEmptyValues([tn_ved, add_tn_ved, title, description_combined]).join(' ')
      })
    },
    getIdFromEvent(event){
      const fromRow = event.fromElement.closest('tr')
      const targetRow = event.target.closest('tr')
      if(fromRow === targetRow) return false
      const classList = targetRow?.classList ?? []
      const idString = [...classList].find(i => i.startsWith('item-id-'))
      if(!idString) return false
      return Number(idString.replace('item-id-', ''))
    },
    bufferWareMouseMove(event){
      if(!event.buttons) return false
      this.bufferClearSelection()
      const id = this.getIdFromEvent(event)
      if(!id) return false
      if(this.bufferWaresIds.includes(id)){
        return this.$store.commit('buffer/REMOVE_WARE', id)
      }
      // items - массив в компоненте
      const {tn_ved, add_tn_ved, title, description_combined} = this.items.find(i => i.id === id)
      this.$store.commit('buffer/ADD_WARE',{
        id,
        module:this.selectedDocumentType,
        title: this.removeEmptyValues([tn_ved, add_tn_ved, title, description_combined]).join(' ')
      })
    },
    bufferDocumentMouseMove(event){
      if(!event.buttons) return false
      this.bufferClearSelection()
      const id = this.getIdFromEvent(event)
      if(!id) return false
      if(this.documentsInBuffer.includes(id)){
        return this.$store.commit('buffer/REMOVE_DOCUMENT', id)
      }
      this.$store.commit('buffer/ADD_DOCUMENT',id)
    },
    bufferDocument(item, payload, event){
      if(!event.shiftKey) return false
      this.bufferClearSelection()
      const {id} = item
      if(!id){
        this.bufferSelectNotification()
      }
      if(this.documentsInBuffer.includes(id)){
        return this.$store.commit('buffer/REMOVE_DOCUMENT', id)
      }
      this.$store.commit('buffer/ADD_DOCUMENT',id)
    }
  }
}