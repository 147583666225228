import {downloadXml, getFilenameFromContentDisposition, downloadFile} from '@/helpers/documents'

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name: "Скачать PDF"
  },
  {
    icon: "mdi-content-copy",
    name: "Копировать документ",
    action: "copyDocument",
    divider: true,
    component: () => import('@/components/zvt/journal/copy-document.vue')
  },
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать xml",
    action: "downloadXml"
  },
  {
    icon: "mdi-delete",
    name: "Удалить документ",
    action: "deleteDocument",
  },
  {
    icon: "mdi-account",
    name: "Изменить исполнителя",
    action: "changePerformer",
    divider: true,
    component: () => import('@/components/documents/change-performer.vue')
  },
  null,
  {
    icon:"mdi-pencil",
    name:"Прикрепить счет и акт",
    action:"attachWorksAct",
    component:() => import('@/components/zvt/journal/attach-works-act.vue')
  },
  {
    icon:"mdi-tray-arrow-down",
    name:"Скачать документы заявки",
    action:"downloadDocumentsArchive"
  },
  {
    icon:"mdi-plus",
    name:"Добавить документы",
    action:"addDocuments",
    component:() => import('@/components/shared/add-documents.vue')
  },
]

const methods = {
  getActionIndex(actionId) {
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  downloadXml({id}) {
    this.$store.dispatch("zvt/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}) {
    this.$store.dispatch('zvt/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data: res.data, type: 'application/pdf', name})
    }).catch(() => this.$error())
  },
  copyDocument({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  deleteDocument({id, item}) {
    const value = !item.is_trash
    this.$store.dispatch('zvt/toTrash', {id, value}).then(() => {
      this.$success("Документ удален")
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  changePerformer({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  attachWorksAct({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  downloadDocumentsArchive({id}){
    this.$store.dispatch("zvt/getDocumentArchive", id)
      .then((res) => {
        if (res.data.size < 1) return this.$info( "Документы отсутствуют");
        downloadFile({ data: res.data, type: "application/zip" });
      }).catch(() => this.$error());
  },
  addDocuments({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },

}

export {
  methods, actions
}
