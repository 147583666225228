import {DOCUMENTS} from "@/http-routes";
import {documentTypes} from "@/helpers/common";

export const JOURNAL_SEARCH_PLACES= [
  {
    name: "Товар",
    value: "wareCondition",
    description: "Товар: артикул, наименование, описание, ТНВЭД",
    available: ["sd","td", "rs", "epi","dt", "zvt", "kdt"],
  },
  {
    name: "Заполнил",
    value: "fillerCondition",
    description: "Лицо заполнившее декларацию: фамилия",
    available: ["sd", "dt", "zvt", "kdt"],
  },
  {
    name: "Документ",
    value: "documentCondition",
    description: "Документ: номер",
    available: ["sd", "td", "rs", "epi", "dt", "zvt", "kdt"],
  },
  {
    name: "Контрагент",
    value: "counterAgentCondition",
    description: "Контрагент: УНП, наименование (Заявитель, Отправитель, Получатель)",
    available: ["zvt"],
  },
  {
    name: "Контрагент",
    value: "counterAgentCondition",
    description: "Контрагент: УНП, наименование (Декларант, Отправитель, Получатель)",
    available: ["dt", "kdt"],
  },
  {
    name: "Контрагент",
    value: "unpCondition",
    description: "Контрагент: УНП, наименование (Контрагент, Резидент)",
    available: ["sd"],
  },
  {
    name: "Hомер",
    value: "outNumberCondition",
    description: "Номер: ID, исходящий, регистрационный, разрешение",
    available: ["sd","td", "rs", "epi","dt", "so","ptd", "zvt", "kdt"],
  },
  {
    name: "Отправитель",
    value: "senderCondition",
    description: "Отправитель: УНП, наименование",
    available: ["td", "epi"],
  },
  {
    name: "Перевозчик",
    value: "carrierCondition",
    description: "Перевозчик: УНП, наименование (Перевозчик, Принципал)",
    available: ["td", "epi"],
  },
  {
    name: "Водитель",
    value: "driverCondition",
    description: "Водитель: ФИО, номер паспорта",
    available: ["td", "epi"],
  },
  {
    name: "Транспорт",
    value: "transportCondition",
    description: "Транспорт: номер ТС гр 18,21 и перегрузки",
    available: ["td", "epi"],
  },
  {
    name: "Декларант",
    value: "declarantCondition",
    description: "Декларант: УНП, фамилия, номер документа",
    available: ["rs", "so"],
  },
  {
    name: "Декларант",
    value: "guarantorCondition",
    description: "Декларант: фамилия, номер документа",
    available: ["ptd"],
  },
  {
    name: "Лицо, заполнившее декларацию",
    value: "fillerCondition",
    description: "Лицо, заполнившее декларацию: фамилия",
    available: ["ptd"],
  },
]

export function getDefaultSearchPlaceForType(type){
  return JOURNAL_SEARCH_PLACES.find((place) => place.available.includes(type))?.value ?? null
}

export function getJournalSearchPlaces(){
  return JOURNAL_SEARCH_PLACES
}


export const getUrl = (state) => {
  const { documentType } = state;
  const type = documentType.toString().toUpperCase();
  const url = DOCUMENTS[type];
  if (url) return url;
  throw new Error("Ошибка определения модуля");
};

export const getJournalUrl = (state) => {
  const { documentType } = state;
  const type = documentType.toString().toUpperCase();
  return type === 'PASS' ? 'pass/getPassJournal' : 'declaration/getDeclarationsJournal'
}

export const getCurrentTypedParams = (state) => {
  const type = state.documentType;
  const params = state.typeDependentParams.filter((i) => i.type === type);
  return params.reduce((prev, current) => {
    const { name, value } = current;
    return value ? `${prev}&${name}=${value}`.trim() : "";
  }, "");
};

export const getSelectedType = (state) => {
  return state.selectType ? `&selectType=${state.selectType}` : "";
};

export function filterDefault(){
  return {
    statuses: "",
    declarationDateFrom:null,
    declarationDateTo:null,
    favorite: false,
    controlled: false,
    trash: false,
  }
}
export const journalDocumentTypes = () => documentTypes().map(({name, value}) => ({name, value}))
