<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <div
          class="statistics-box pa-4"
          :class="{
            'blocked-box':disabledView
          }"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="9"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    Общие характеристики товаров
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  class="justify-end d-flex"
                  style="z-index: 2"
                >
                  <v-text-field
                    v-model="searchValue"
                    outlined
                    tabindex="-1"
                    dense
                    clearable
                    hide-details="auto"
                    placeholder="Поиск по товарам"
                    background-color="white"
                    class="rounded-r-0"
                    @keypress.enter="search"
                    @click:clear="clearSearch"
                  />
                  <v-btn
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    dark
                    class="elevation-0 rounded-l-0"
                    @click="search"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    tabindex="-1"
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    color="#5CB7B1"
                    class="ml-3 px-0 elevation-0"
                    @click="togglePin"
                  >
                    <v-icon>{{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                :id="block_id"
                v-click-outside="triggerOnFocus"
              >
                <v-col
                  cols="6"
                >
                  <div class="d-flex align-center">
                    <div>
                      <label>
                        Страна происхожд. </label>
                      <v-autocomplete
                        ref="origin_country_letter"
                        v-model="about_wares.origin_country_letter"
                        class="mr-2"
                        style="width:140px;"
                        auto-select-first
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :filter="filterBySearchField"
                        :items="countries"
                        item-text="text"
                        item-value="letterCode"
                      />
                    </div>
                    <div>
                      <label>
                        Мест
                      </label>
                      <input-numeric
                        v-model="cargoPlaceQuantitySum"
                        class="mr-2"
                        outlined
                        dense
                        hide-details="auto"
                        disabled
                        background-color="grey lighten-2"
                      />
                    </div>

                    <div>
                      <label>
                        Вес брутто
                        <field-menu>
                          <v-list-item
                            button
                            @click="
                              fillWeight('netWeight', 'вес брутто')
                            "
                          >
                            Брутто пропорционально нетто
                          </v-list-item>
                          <v-list-item
                            button
                            @click="fillPackageWeight"
                          >
                            Добавить вес упаковки
                          </v-list-item>
                        </field-menu>
                      </label>
                      <input-numeric
                        v-model="grossWeightSum"
                        class="mr-2"
                        outlined
                        dense
                        hide-details="auto"
                        disabled
                        background-color="grey lighten-2"
                      />
                    </div>
                    <div>
                      <label>Вес нетто
                        <field-menu>
                          <v-list-item
                            button
                            @click="fillNetWeightNoPackFromNetWeight"
                          >
                            Нетто без упаковки = нетто
                          </v-list-item>
                          <v-list-item
                            button
                            @click="
                              fillWeight('netWeightNoPack', 'вес нетто')
                            "
                          >
                            Нетто пропорционально нетто без упаковки
                          </v-list-item>
                          <v-list-item
                            button
                            @click="fillWeight('grossWeight', 'вес нетто')"
                          >
                            Нетто пропорционально брутто
                          </v-list-item>
                        </field-menu>
                      </label>
                      <input-numeric
                        v-model="netWeightSum"
                        class="mr-2"
                        outlined
                        dense
                        hide-details="auto"
                        disabled
                        background-color="grey lighten-2"
                      />
                    </div>
                    <span
                      class="mr-2 mt-5"
                    >/</span>
                    <div>
                      <label>(без упаковки)</label>
                      <input-numeric
                        v-model="netWeightSumNoPack"
                        outlined
                        dense
                        hide-details="auto"
                        disabled
                        background-color="grey lighten-2"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                >
                  <div class="d-flex align-center">
                    <div>
                      <label>
                        22. Валюта и общая стоимость
                      </label>
                      <div class="d-flex align-center">
                        <v-autocomplete
                          ref="invoice_currency_letter"
                          v-model="about_wares.invoice_currency_letter"
                          style="width:110px;"
                          class="mr-2"
                          auto-select-first
                          :items="currencies"
                          item-text="letterCode"
                          item-value="letterCode"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :filter="filterBySearchField"
                        />
                        <input-numeric
                          ref="invoice_cost"
                          v-model="about_wares.invoice_cost"
                          class="mr-2"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :fraction="2"
                        />
                        <span class="mr-2">/</span>
                        <input-numeric
                          v-model="invoiceCostSum"
                          disabled
                          class="mr-2"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="grey lighten-2"
                          :fraction="2"
                        />
                      </div>
                    </div>
                    <div>
                      <label>Общая ТС
                        <field-menu>
                          <v-list-item @click="clearWaresCustomsCost">
                            Очистить таможенную стоимость
                          </v-list-item>
                        </field-menu>
                      </label>
                      <div class="d-flex align-center">
                        <input-numeric
                          ref="customs_cost"
                          :value="about_wares.customs_cost"
                          outlined
                          class="mr-2"
                          dense
                          hide-details="auto"
                          disabled
                          suffix="BYN"
                        />
                        <v-btn
                          max-width="40px"
                          min-width="40px"
                          height="40px"
                          class="ml-2 elevation-0"
                          color="#5CB7B1"
                          dense
                          @click="recalculateCustomsCost"
                        >
                          <v-icon>mdi-calculator</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="show"
      :params="activeComponentParams"
    />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {getWaresParamTotal} from "@/helpers/math";
import {normalizeFloat} from "@/helpers/math";
import {getCountryNameByCode} from "@/helpers/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import {about_wares as onAboutWares} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import {eventBus} from "@/bus";
import FieldMenu from "@/components/documents/field-menu.vue";
import {getReferenceAsync, waitAsyncDecision, waitResultOrDie} from "@/helpers/async-component";
import RecalculateWeightModal from "@/components/dt/goods-characters/recalculate-weight-modal.vue";
import {capitalizeString} from "@/helpers/text";

export default {
  components: {FieldMenu, InputNumeric},
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, copyFieldMixin],
  data: () => ({
    pinName: "goods-character",
    block_id: "dt-goods-character",
    activeComponent: null,
    show: false,
    asyncDecision: {
      resolve: null,
      reject: null,
    },
    activeComponentParams: {
      label: "",
      field: null,
    },
    searchValue: "",
    about_wares: {
      declaration_id: null,
      gross_weight: null,
      invoice_cost: null,
      invoice_currency_letter: null,
      net_weight: null,
      origin_country_letter: null,
      origin_country_name: null,
      package_type_code: null,
      customs_cost: null
    }
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      wares: "dt/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies",
      pinned: "ui/getPinned",
      requests: "dt/getRequests",
      packageTypes: 'catalogs/getNsiPackageTypes',
      countries: 'catalogs/getNsiCountries',
      disabledView: "dt/getVisibility"
    }),
    cargoPlaceQuantitySum() {
      return getWaresParamTotal(this.wares, "cargo_place_quantity");
    },
    grossWeightSum() {
      return getWaresParamTotal(this.wares, "gross_weight");
    },
    netWeightSum() {
      return getWaresParamTotal(this.wares, "net_weight")
    },
    netWeightSumNoPack() {
      return getWaresParamTotal(this.wares, "net_weight_no_pack")
    },
    invoiceCostSum() {
      return this.getTotal("invoice_cost");
    },    
  },
  watch: {
    show(value) {
      if (value === false) this.activeComponent = null
    },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true,
    },
  },
  created() {
    onAboutWares.subscribe(this.highlightField)
    eventBus.$on("recalculateCustomsCost", this.recalculateCustomsCost);
  },
  beforeDestroy() {
    onAboutWares.unsubscribe()
    eventBus.$off("recalculateCustomsCost", this.recalculateCustomsCost);
  },
  methods: {
    highlightField,
    getReferenceAsync,
    waitAsyncDecision,
    waitResultOrDie,
    showPrompt(field, label) {
      this.activeComponent = RecalculateWeightModal
      this.activeComponentParams = {...this.activeComponentParams, field, label}
      this.show = true
      return this.waitAsyncDecision()
    },
    fillPackageWeight(){
      this.showPrompt('packageWeight', 'вес упаковки')
        .then(this.waitResultOrDie)
        .then(this.updatePackageWeight)
        .catch(() => {})
    },
    updatePackageWeight(weight){
      return this.$store.dispatch('dt/calcPackageWeight', {id: this.selected.id, weight})
        .then(() => this.$success("Вес обновлен"))
        .catch((err) => this.$error(capitalizeString(err.response.data)))
    },
    fillWeight(field, label) {
      this.showPrompt(field, label)
        .then(this.waitResultOrDie)
        .then((weight) => this.updateWeight({field, weight}))
        .catch(() => {})
    },
    updateWeight({field, weight}) {
      return this.$store.dispatch('dt/calcWeightWithParam', {
        field,
        weight,
        id: this.selected.id
      }).then(() => this.$success("Вес обновлен"))
        .catch(() => this.$error())
    },
    fillNetWeightNoPackFromNetWeight() {
      this.$store.dispatch('dt/fillNetWeightNoPackFromNetWeight', this.selected.id)
        .then(() => this.$success("Вес обновлен"))
        .catch(() => this.$error())
    },
    search() {
      this.$store
        .dispatch("dt/searchInWares", {
          id: this.selected.id,
          value: this.searchValue,
        })
        .catch(() => this.$error('Не удалось выполнить поиск'))
    },
    clearSearch() {
      this.$store
        .dispatch("dt/fetchGoods", this.selected.id)
        .then((res) => {
          this.$store.commit(
            "dt/REPLACE_GOODS_ITEM_IN_DECLARATION",
            res.data
          );
        }).catch(() => this.$error('Не удалось загрузить товары'));
    },
    clearWaresCustomsCost() {
      this.about_wares.customs_cost = null
      this.uploadData().then(() => {
        return this.copyToAll('customs_cost', null)
      }).catch(() => this.$error())
    },
    getTotal(field) {
      if (this.wares) {
        const value = this.wares.reduce((acc, current) => {
          return acc + (current[field] || 0);
        }, 0);
        return normalizeFloat({value});
      }
      return "";
    },
    replaceFloatNumbers({value, prop, afterPoint = null}) {
      this.about_wares[prop] = normalizeFloat({value, afterPoint});
    },
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
    recalculateCustomsCost() {
      const requests = this.requests;
      Promise.all(requests)
        .then(() => this.$store.dispatch("dt/recalculateCustomsCost", this.selected.id))
        .catch(() => this.$error());
    },
    setFields() {
      const about_wares = this.selected.about_wares ?? {}
      Object.keys(about_wares).forEach(key => {
        if (key in this.about_wares) {
          this.about_wares[key] = about_wares[key];
        }
      })
    },
    uploadData() {
      const about_wares = this.convertEmptyStringsToNull({
        ...this.about_wares,
        origin_country_name: getCountryNameByCode(this.countries, this.about_wares.origin_country_letter)
      });

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "about_wares",
        value: about_wares,
      });
    }
  },
};
</script>

<style scoped>
table,
tbody,
tr,
th,
td {
  background-color: #ededed !important;
}

th {
  border-bottom: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

td {
  border-bottom: transparent !important;
  padding: 0 10px 10px 0 !important;
}

table tr:last-child td {
  padding: 0 10px 4px 0 !important;
}

.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative;
}

.document-add {
  position: absolute;
  bottom: 7%;
  right: 24%;
}

.root-width {
  width: 1440px;
}

.pinned {
  position: fixed;
  top: 0;
  right: 50%;
  width: 100vw;
  transform: translateX(50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
</style>
