// import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import store from "../store/index";
import {SOCKET} from "@/http-routes";
// import router from "@/router";
// import {getNamespaceByService, getStoreNamespaceByJournalDoc} from "@/helpers/common";
const host = window.location.hostname
const customIp = host === 'web.declarant.by';
const AUTH = customIp ? 'wss://web.declarant.by/api/auth/' : 'ws://10.128.8.142:7020/auth/';
// function resolveDivisionEvents(event){
//   const [serviceName, ...parts] = event.split('_')
//   const action = parts.join('_')
//   const namespace = getNamespaceByService(serviceName)
//   const currentRoute = router.history.current.path
//   const currentDocumentInJournal = getStoreNamespaceByJournalDoc(store.getters['journal/getDocumentType'])
//   if(
//     ["update_statuses", "create_declaration"].includes(action) &&
//     currentRoute === '/journal' &&
//     namespace === currentDocumentInJournal
//   ){
//     store.dispatch(`journal/fetchItems`)
//     this.$snackbar({text: `Обновление журнала...`, right: true, timeout: 3000})
//   }
// }

// Для отображения обновления нескольких статусов
function displayMultipleStatuses(event){
  event.forEach(document => {
    const text = `Документу ${document.ru_type} (исх.№ ${document.out_number}) присвоен новый статус: '${document.status}'`
    this.$snackbar( { text: text, right: true, timeout: 3000} )
  })
}


function unset() {
  this.user = null
  this.socket = null
  this.subscription = null
}

function unsubscribe() {
  if (!this.subscription) return;
  this.socket.disconnect()
  this.unset()
}

const commonProps = {
  user: null,
  socket: null,
  subscription: null,
  unset,
  unsubscribe
}
export function division() {
  const context = this

  return {
    ...commonProps,
    subscribe() {
      try {
        this.user = store.getters['auth/getUser']
        this.socket = Stomp.client(SOCKET);
        // success
        const successCallback = () => {
          context.$snackbar({text: `Автообновление:вкл.`, color: 'dark', right: true})
          // отключил resolveDivisionEvents (УДАЛИТЬ И РАСКОММЕНТИТЬ)
          // const event = `/division/${this.user.user.activeDivisionId}`
          if (this.subscription?.id) {
            this.socket.unsubscribe();
          }
          // отключил resolveDivisionEvents (УДАЛИТЬ И РАСКОММЕНТИТЬ)
          // this.subscription = this.socket.subscribe(event, function (response) {
          //   const {event_name} = JSON.parse(response.body)
          //   resolveDivisionEvents.call(context, event_name)
          // });
        }
        // error
        const errorCallback = () => {
          context.$snackbar({text: `Ошибка автообновления`, right: true, color: 'red', timeout: 5000})
        }


        this.socket.connect({}, successCallback, errorCallback);
      } catch (e) {
        console.log(e)
      }
    },

    unsubscribe() {
      if (!this.subscription) return;
      this.socket.disconnect()
      context.$snackbar({text: `Автообновление:откл.`, right: true, color: 'dark'})
      this.unset()
    },
  }

}

export function notification(){
  return{
    ...commonProps,
    subscribe () {
      try {
        this.user = store.getters['auth/getUser']
        this.socket = Stomp.client(SOCKET);

        const successCallback = () => {
          const event = `/user/${this.user.user.id}/notViewedNotificationsCount`
          if (this.subscription?.id) {
            this.socket.unsubscribe();
          }
          this.subscription = this.socket.subscribe(event, function () {
            store.dispatch('notification/fetchNewNotificationsAmount')
          });
        }
        const errorCallback = () => {
          return false
        }
        this.socket.connect({}, successCallback, errorCallback);
      } catch (e) {
        console.log(e)
      }

    },

  }
}

export function docStatusesNotification() {
  const context = this;
  return {
    ...commonProps,
    subscribe() {
      try {
        this.user = store.getters['auth/getUser'];
        this.socket = Stomp.client(SOCKET);
        // success
        const successCallback = () => {
          const event = `/user/${this.user.user.id}/declarationChangeStatus`
          if (this.subscription?.id) {
            this.socket.unsubscribe();
          }
          this.subscription = this.socket.subscribe(event, function (response) {
            const statuses = store.getters['notification/getDocumentsStatuses'];
            statuses.push(...JSON.parse(response.body));
            store.commit('notification/SET_DOCUMENT_STATUSES', statuses);
            displayMultipleStatuses.call(context, JSON.parse(response.body))
          });
        }
        // error
        const errorCallback = () => {
          return false
        }
        this.socket.connect({}, successCallback, errorCallback);
      } catch (e) {
        console.log(e)
      }
    },
  }
}

export function session(){
  return {
    ...commonProps,
    subscribe () {
      try {
        this.user = store.getters['auth/getUser']
        this.socket = Stomp.client(`${AUTH}auth-websocket`);
        const successCallback = () => {
          const event = `/user/${this.user.user.id}/inviteList`
          if (this.subscription?.id) {
            this.socket.unsubscribe();
          }
          this.subscription = this.socket.subscribe(event, function () {
            store.dispatch('auth/loadInvites')
          });
        }
        const errorCallback = () => {
          return false
        }
        this.socket.connect({}, successCallback, errorCallback);
      } catch (e) {
        console.log(e)
      }
    },
  }
}


