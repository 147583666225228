import axios from "axios";
import { SD } from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";
const documentActions = documentActionsFactory(SD);
export default {
  namespaced: true,
  state: {
    selected: {},
    control: [],
    selectedWares: [],
    selectedId: null,
    editableWare: { id: null, index: null },
    requests: [], // Промежуточные асинхронные запросы которые наблюдаются разными блоками документа
    searchResult: [],
    extraTnvedOptions: {
      firstOptions:[],
      secondOptions:[],
      thirdOptions:[],
      fourthOptions:[]
    }
  },
  mutations: {
    ...documentMutations,
    SET_EXTRA_TNVED_OPTIONS(state, preparedOptions){
      state.extraTnvedOptions = preparedOptions
    },
    SET_SEARCH_RESULT(state, payload) {
      state.searchResult = payload;
    },
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    SET_SELECTED_ID(state, id) {
      state.selectedId = id;
    },

    // обновить массив блоков например при сортировке, удалении товара и тд,
    // когда возвращается несколько блоков
    REPLACE_BLOCK_IN_DECLARATION(state, blocks) {
      Object.keys(blocks).forEach((block) => {
        if (block in state.selected) {
          state.selected[block] = blocks[block];
        }
        if (block === "wares") {
          state.selectedWares = blocks[block];
        }
      });
    },
    ADD_COPIED_DOCUMENTS(state, documents) {
      state.selected.presented_documents = [
        ...state.selected.presented_documents,
        ...documents,
      ];
    },
    // ДОКУМЕНТЫ
    DELETE_DOCUMENT(state, { index }) {
      console.log("DELETE_DOCUMENT", index);
      state.selected.presented_documents.splice(index, 1);
    },
    DELETE_DOCUMENTS(state, ids) {
      let arr = state.selected.presented_documents;
      ids.forEach((item) => {
        let arrItem = arr.find((i) => i.id === item);
        if (arrItem) {
          arr.splice(arr.indexOf(arrItem), 1);
        }
      });

      state.selected.presented_documents = arr;
    },
    // ДОКУМЕНТЫ - КОНЕЦ
    //
    // ТОВАРЫ
    ADD_GOODS_ITEM(state, { item, nextItemPosition }) {
      state.selectedWares.splice(nextItemPosition, 0, item);
    },
    UPDATE_GOODS_ITEM_IN_DECLARATION(state, items) {
      items.forEach((item) => {
        const { id } = item;
        const current = state.selectedWares.find((i) => i.id === id);
        const index = state.selectedWares.indexOf(current);
        console.log("STORE UPDATE GOOD idx >>", index);
        state.selectedWares.splice(index, 1, item);
      });
    },
    DELETE_GOODS_ITEM(state, { id }) {
      const current = state.selectedWares.find((i) => i.id === id);
      const index = state.selectedWares.indexOf(current);
      console.log("STORE DELETE_GOODS_ITEM", index);
      state.selectedWares.splice(index, 1);
    },
    RESET_GOODS_AND_DOCS(state, payload) {
      state.selectedWares = payload.wares;
      if (payload.presented_documents) {
        state.selected.presented_documents = payload.presented_documents;
      }
    },
    RESET_GOODS(state, payload) {
      state.selectedWares = payload;
    },
    // ТОВАРЫ - КОНЕЦ
    //
    SET_CONTROLLED(state, value) {
      state.selected = {
        ...state.selected,
        controlled: value,
      };
    },
    SET_FAVORITE(state, value) {
      state.selected = {
        ...state.selected,
        favorite: value,
      };
    },
  },
  actions: {
    ...documentActions,
    // Все статистики
    fetchItems({ commit, rootState }) {
      const userId = rootState?.auth?.user?.user?.id;
      axios
        .get(
          `${SD}declaration/getDeclarationsJournal?userId=${userId}&selectType=USER`
        )
        .then((res) => {
          commit("SET_ITEMS", res.data);
        })
        .catch((e) => console.log(e));
    },
    // СОЗДАНИЕ СД
    createNewDocument({ rootState }) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${SD}declaration/create?userId=${userId}`);
    },
    // ДОБАВИТЬ ТОВАР
    addNewGoodsItem({ commit, state }) {
      let index;
      let nextItemPosition;
      let lastElement;

      if (state.selectedWares.length !== 0) {
        lastElement = state.selectedWares[state.selectedWares.length - 1];
        if (!lastElement.sort_index) {
          console.log("SORT INDEX NOT FOUND");
          return;
        }
        index = lastElement.sort_index + 1;
        nextItemPosition = state.selectedWares.length;
      } else {
        nextItemPosition = 0;
        index = 1;
      }
      return axios
        .post(
          `${SD}ware/create?declarationId=${state.selectedId}&sortIndex=${index}`
        )
        .then((res) => {
          commit("ADD_GOODS_ITEM", { item: res.data, nextItemPosition });
          return res;
        });
    },
    // ОБНОВИТЬ ТОВАР
    updateGoodsItem({ commit }, data) {
      const payload = Array.isArray(data) ? data : [data];
      return axios
        .post(`${SD}ware/updateAll`, payload)
        .then((res) => {
          commit("UPDATE_GOODS_ITEM_IN_DECLARATION", res.data);
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // СОРТИРОВАТЬ ТОВАРЫ
    sortGoods({ state, commit }, { sortBy = "id" }) {
      const id = state.selected.id;
      if (!id) return;
      axios
        .post(`${SD}ware/sortWares?declarationId=${id}&sortBy=${sortBy}`)
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateGoodsCorrectionReasonCode({ dispatch, state }) {
      const wares = state.selectedWares;
      const payload = wares.map((ware) => {
        return {
          ...ware,
          correction_reason_code: null,
        };
      });
      dispatch("saveAndReplaceGoods", payload);
    },
    // ОБНОВЛЯЕТ В БД И ЗАМЕНЯЕТ МАССИВ ЭЛЕМЕНТОВ В ДОКУМЕНТЕ
    saveAndReplaceGoods({ commit }, data) {
      const payload = Array.isArray(data) ? data : [data];
      return axios
        .post(`${SD}ware/updateAll`, payload)
        .then((res) => {
          commit("REPLACE_GOODS_ITEM_IN_DECLARATION", res.data);
        })
        .catch((e) => console.log(e));
    },
    //ПОЛУЧИТЬ АРТИКУЛЫ У ТОВАРА
    fetchWareDetails(_, { wareId }) {
      return axios.get(`${SD}wareDetails/getByWareId?wareId=${wareId}`);
    },
    // СОЗДАТЬ АРТИКУЛ У ТОВАРА
    createWareDetail(_, { wareId, index }) {
      return axios.post(
        `${SD}wareDetails/create?wareId=${wareId}&sortIndex=${index}`
      );
    },
    deleteWareDetail(_, { wareId }) {
      return axios.post(`${SD}wareDetails/deleteById?id=${wareId}`);
    },
    updateWareDetail(_, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios.post(`${SD}wareDetails/updateAll`, data);
    },
    copyWareDetail(_, payload) {
      return axios.post(
        `${SD}wareDetails/copyWareDetails?id=${payload.id}&sortIndex=${payload.index}`
      );
    },
    // УДАЛИТЬ ТОВАР
    deleteGoodsItem({ commit }, { id }) {
      return axios.post(`${SD}ware/deleteByIds`, [id]).then((res) => {
        commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
        return res;
      });
    },
    deleteAllGoods({ commit, state }) {
      return axios
        .post(`${SD}ware/deleteByIds?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("RESET_GOODS_AND_DOCS", res.data);
          return res;
        });
    },
    // УДАЛИТЬ ДОКУМЕНТЫ
    deleteDocumentArr({ commit, state }, selected) {
      const declarationId = state.selected.id;
      return axios
        .post(
          `${SD}presentedDocument/deleteByIds?declarationId=${declarationId}`,
          [...selected]
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", {
            presented_documents: res.data || [],
          });
          return res;
        });
    },
    // КОПИРОВАТЬ ДОКУМЕНТЫ
    copyDocumentArr({ commit, state }, selected) {
      const declarationId = state.selected.id;
      return axios
        .post(`${SD}presentedDocument/copy?declarationId=${declarationId}`, [
          ...selected,
        ])
        .then((res) => {
          commit("ADD_COPIED_DOCUMENTS", res.data);
          return res;
        });
    },
    getXmlString(_, declarationId) {
      return axios.get(`${SD}declaration/getXml?declarationId=${declarationId}`);
    },
    sendSignedXmlString(
      _,
      { xml, ptoId, fileGuid, remark, divisionId, declarationId }
    ) {
      return axios.post(
        `${SD}declaration/sendToNces?divisionId=${divisionId}`,
        { xml, ptoId, fileGuid, remark, declarationId }
      );
    },
    attachWorksAct(_, formData) {
      return axios.post(`${SD}order/attachWorksAct`, formData);
    },
    getDocumentArchive(_, id) {
      return axios
        .get(`${SD}order/downloadDocuments?declarationId=${id}`, {
          responseType: "blob",
        })
    },
    // добавляет файл в архив отправленных документов
    // для заявки только в кабинете заявителя
    addDocuments(_, formData) {
      return axios.post(`${SD}order/attachDocuments`, formData);
    },
    uploadExcel(
      { commit },
      { formData, id, joinArticleDescription, unknownCountry, overwrite }
    ) {
      return axios
        .post(
          `${SD}declaration/uploadXlsx?declarationId=${id}` +
            `&joinArticleDescription=${joinArticleDescription}` +
            `&unknownCountry=${unknownCountry}&overwrite=${overwrite}`,
          formData
        )
        .then((res) => {
          commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
          return res;
        });
    },
    copyDocument(
      { rootState },
      {
        id,
        copySubject,
        copyCounterAgent,
        copyDocuments,
        copyAllWares,
        wareIds,
        options,
      }
    ) {
      const uid = rootState?.auth?.user?.user?.id;
      const divisionId = rootState?.auth?.user?.user?.activeDivisionId;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      if (!divisionId)
        return Promise.reject(new Error("Отсутствует ID подразделения"));
      let url =
        `${SD}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&divisionId=${divisionId}` +
        `&copySubject=${copySubject}` +
        `&copyCounterAgent=${copyCounterAgent}` +
        `&copyDocuments=${copyDocuments}` +
        `&userId=${uid}` +
        `&copyAllWares=${copyAllWares}`
        ;
      Object.keys(options).forEach((key) => {
        if (options[key] === null) {
          options[key] = false;
        }
        url += `&${key}=${options[key]}`;
      });
      return axios.post(url, wareIds);
    },
    // подсчет нетто
    fillNetWeightNoPackFromNetWeight({ commit }, payload) {
      axios
        .post(
          `${SD}ware/fillNetWeightNoPackFromNetWeight?declarationId=${payload}`
        )
        .then((res) => {
          commit("RESET_GOODS", res.data);
        })
        .catch((e) => console.log(e));
    },
    calcWeightWithParam({ commit }, payload) {
      axios
        .post(
          `${SD}ware/calculateWeight?declarationId=${payload.id}&weight=${payload.weight}&fromField=${payload.field}`
        )
        .then((res) => {
          commit("RESET_GOODS", res.data);
        })
        .catch((e) => console.log(e));
    },
    calcPackageWeight({ commit }, payload) {
      return axios
        .post(
          `${SD}ware/recalculateGrossWeightByAddingPackage?declarationId=${payload.id}&commonGrossWeight=${payload.weight}`
        )
        .then((res) => {
          commit("RESET_GOODS", res.data);
          return res;
        });
    },
    updateDeal({ state, dispatch }, payload) {
      const { deal_details_json, declaration_id } = state.selected.deal_details;
      const data = {
        name: "deal_details",
        value: {
          deal_details_json: {
            ...deal_details_json,
            ...payload,
          },
          declaration_id,
        },
      };
      return dispatch("uploadBlockData", data);
    },
    recalcStatCost({ commit, state }) {
      const id = state.selected.id;
      if (!id) return Promise.reject(new Error("Отсутствует id документа"));
      return axios
        .post(`${SD}ware/recalculateStatisticalCost?declarationId=${id}`)
        .then((res) => {
          commit("REPLACE_GOODS_ITEM_IN_DECLARATION", res.data);
          return res;
        });
    },
    downloadPdf(_, id) {
      return axios.get(`${SD}declaration/pdf?id=${id}`, {
        responseType: "blob",
      });
    },
    setControlled({ commit, state }) {
      const id = state.selected.id;
      const newValue =
        state.selected.controlled === null ||
        state.selected.controlled === false;
      if (!id) return Promise.reject(new Error("Отсутствует id документа"));
      return axios
        .post(
          `${SD}declaration/setControlled?declarationId=${id}&controlled=${newValue}`
        )
        .then((res) => {
          commit("SET_CONTROLLED", newValue);
          return res;
        });
    },
    setFavorite({ commit, state }) {
      const id = state.selected.id;
      const newValue =
        state.selected.favorite === null || state.selected.favorite === false;
      if (!id) return Promise.reject(new Error("Отсутствует id документа"));
      return axios
        .post(
          `${SD}declaration/setFavorite?declarationId=${id}&favorite=${newValue}`
        )
        .then((res) => {
          commit("SET_FAVORITE", newValue);
          return res;
        });
    },
    getXml(_, id) {
      return axios.get(`${SD}declaration/export?declarationId=${id}`);
    },
    performControlNCES(_, id) {
      if (!id) return Promise.reject(new Error("Отсутствует номер документа"));
      return axios.get(`${SD}declaration/flk?declarationId=${id}`);
    },
    recyclingReport(_, { from, to, unp }) {
      return axios.get(
        `${SD}reports/recycling?unp=${unp}&dateFrom=${from}&dateTo=${to}`,
        { responseType: "blob" }
      );
    },
    employeeEfficiency(_, { from, to, withCorrection, withCounterAgent }) {
      return axios.get(
        `${SD}reports/employeeEfficiency?dateFrom=${from}&dateTo=${to}&withCorrection=${withCorrection}&withCounterAgent=${withCounterAgent}`,
        { responseType: "blob" }
      );
    },
    recalculateCostInBYN({dispatch}, id) {
      return axios.post(`${SD}ware/recalculateInvoiceCostInBYN?declarationId=${id}`)
        .then((res) => {
          dispatch('getDocumentById', id)
          return res
        })
    },
    addExtraTnvedRules({commit}, {declarationId, rules}){
      return axios.post(`${SD}ware/applyAddTnVedRule?declarationId=${declarationId}`, rules)
        .then((res) => {
          commit('REPLACE_BLOCK_IN_DECLARATION', {
            wares:res.data
          })
        })
    }
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getSelectedId: (s) => s.selectedId,
    getEditableWare: (s) => s.editableWare,
    getRequests: (s) => s.requests,
    getControl: (s) => s.control,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getExtraTnvedOptions: (s) => s.extraTnvedOptions
  },
};
