<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
      />
      <div class="white pa-5 rounded-0">
        <v-simple-table
          fixed-header
          height="500"
        >
          <template>
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.value"
                  scope="row"
                  class="text-left"
                >
                  {{ header.text }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in items"
                :key="idx"
              >
                <td
                  v-for="header in headers"
                  :key="`${idx}-${header.value}`"
                >
                  {{ item[header.value] }}
                </td>
                <td width="500px">
                  <v-file-input
                    v-model="item.file"
                    :rules="fileRules"
                    :accept="'.pdf, .tif'"
                    dense
                    outlined
                    color="#32a1af"
                    solo
                    flat
                    hide-details="auto"
                    show-size
                    placeholder="Выберите файл"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="d-flex justify-end mt-4">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="#5CB7B1"
            elevation="0"
            darks
            @click="submit"
          >
            Отправить
          </v-btn>
        </div>
      </div>
      <component
        :is="activeComponent"
        :show.sync="showChild"
        :params="activeComponentParams"
      />
    </v-card>
  </v-overlay>
</template>
<script>


import formatDate from '@/mixins/format-date.js'
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";
import {getMessageFromNcesResponse, getSignedDocument} from "@/helpers/documents";

export default {
  mixins: [modalWindowMixin, formatDate],
  props: {
    ids: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    loading: false,
    activeComponent: null,
    activeComponentParams: {
      text: "",
    },
    showChild: false,
    fileRules: [
      value => !!value || 'Поле должно быть заполнено',
      value => !value || value.size < 15728640 ||
          'Файл не может превышать размер 15 MB!'
    ],
    items: [],
    headers: [
      {
        text: '№',
        value: 'sort_index'
      },
      {
        text: 'Код',
        value: 'doc_code'
      },
      {
        text: 'Номер',
        value: 'doc_number'
      },
      {
        text: 'Дата',
        value: 'doc_date'
      },
    ],
  }),
  computed: {
    ...mapGetters({
      selected: 'kdt/getSelected',
      user: 'auth/getUser'
    }),
    userId() {
      return this.user?.user?.id
    },
  },
  watch: {
    wares: {
      handler() {
        this.setItems()
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    setItems() {
      this.items = this.selected?.presented_documents
        .filter(item => this.ids.includes(item.id)).map(item => ({
          ...item,
          file: null
        }))
    },
    getSignedDocument,
    submit() {
      this.loading = true
      const formData = new FormData()
      formData.set('declarationId', this.selected.id)
      this.items.forEach((item) => {
        formData.set(item.id, item.file)
      })

      let fileGuid;
      this.getXml(formData)
        .then(({xml, guid}) => {
          fileGuid = guid
          return Promise.resolve({
            xml, guid
          })
        })
        .then(this.getSignedDocument)
        .then((xml) => {
          return Promise.resolve({
            fileGuid,
            xml
          })
        })
        .then(this.sendToDatabase)
        .then(this.fetchDocument)
        .catch(() => {})
        .finally(() => this.loading = false)
    },
    getXml(formData) {
      return this.$store.dispatch('kdt/getBD44Xml', formData)
        .then(res => res.data)
        .catch(err => {
          this.showXsdControlResponse(err.response)
          throw err
        })
    },
    sendToDatabase({fileGuid, xml}) {
      return this.$store.dispatch('kdt/sendBD44ToNces', {
        userId: this.userId,
        payload: {
          xml,
          fileGuid,
          presentedDocumentIds: this.ids,
          declarationId: this.selected.id
        }
      })
        .then((res) => {
          this.$success("Отправлено")
          this.close()
          return res
        }).catch((err) => {
          const message = getMessageFromNcesResponse(err)
          this.$error(message)
          throw err
        })
    },
    fetchDocument(){
      return this.$store.dispatch('kdt/getDocumentById', this.selected.id)
    },
    showXsdControlResponse({data = ""}) {
      this.activeComponentParams.text = data
      this.activeComponent = () => import("@/components/documents/xsd-control-response.vue")
      this.showChild = true
    }
  }
}
</script>
