<template>
  <v-col>
    <declaration-payment
      v-for="(payment, index) in declaration_payments"
      :key="`payment-${index}`"
      :item="payment"
      :index="index"
      :debit-sum="debitSumByPaymentCode"
      @add="openAddModal"
      @debit="openDebitModal"
    />
    <declaration-payments-total :payments="declaration_payments" />
    <component
      :is="activeComponent"
      :show.sync="show"
      :params="activeComponentProps"
      @action="onModalAction"
    />
  </v-col>
</template>
<script>
import DeclarationPayment from "@/components/kdt/graph-b/declaration-payment.vue";
import {mapGetters} from "vuex";
import DeclarationPaymentsTotal from "@/components/kdt/graph-b/declaration-payments-total.vue";
import {OPEN_DEBIT_MODAL} from "@/components/kdt/graph-b/events";


export default {
  components: {DeclarationPaymentsTotal, DeclarationPayment},
  props: {
    debitPayments: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      show: false,
      activeComponent: null,
      activeComponentProps: {
        item: null,
        remains: 0,
        selectedPaymentOrderId:null
      },
      declaration_payments: []
    }
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      user: "auth/getUser",
      disabledView: "kdt/getVisibility",
      requests: "kdt/getRequests"
    }),
    debitSumByPaymentCode() {
      let debitSumByPaymentCode = this.debitPayments.reduce((previousValue, debitPaymentOrder) => {
        const code = debitPaymentOrder.code
        return {
          ...previousValue,
          [code]: (previousValue[code] ?? 0) + debitPaymentOrder.sum
        }
      }, {})
      Object.entries(debitSumByPaymentCode).forEach(([key, value]) => {
        debitSumByPaymentCode[key] = +value.toFixed(2)
      })
      return debitSumByPaymentCode;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onModalAction({action, params}){
      if(action === OPEN_DEBIT_MODAL){
        this.openDebitModal(params)
      }
    },
    mergeActiveComponentProps(props) {
      this.activeComponentProps = {
        ...this.activeComponentProps,
        ...props
      }
    },
    openAddModal({item, remains}) {
      this.mergeActiveComponentProps({item, remains})
      this.activeComponent = () => import('@/components/kdt/graph-b/add-modal.vue')
      this.show = true
    },
    openDebitModal({item, remains, selectedPaymentOrderId = null}) {
      this.mergeActiveComponentProps({item, remains, selectedPaymentOrderId})
      this.activeComponent = () => import('@/components/kdt/graph-b/debit-modal.vue')
      this.show = true
    },
    setFields() {
      if (this.selected.declaration_payments?.length) this.declaration_payments = this.selected.declaration_payments;
      else this.declaration_payments = this.selected.dt_declaration_payments_json ?? [];
    }
  }
}
</script>
