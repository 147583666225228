<template>
  <v-card class="grey lighten-3 elevation-0 rounded-lg pa-3">
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
      fluid
    >
      <v-col
        cols="4"
      >
        <label>Сумма платежей</label>
        <v-text-field
          v-model="common_payment_sum_byn"
          class="mr-1"
          outlined
          dense
          :disabled="isDisabled"
          hide-details="auto"
          :background-color="isDisabled ? 'grey lighten-2' : 'white'"
          suffix="BYN"
        />
      </v-col>
      <v-col
        cols="4"
        align-self="end"
      >
        <v-text-field
          v-model="common_payment_sum_eur"
          class="mr-1"
          outlined
          dense
          :disabled="isDisabled"
          hide-details="auto"
          :background-color="isDisabled ? 'grey lighten-2' : 'white'"
          suffix="EUR"
        />
      </v-col>
      <v-col align-self="end">
        <v-btn
          dense
          class="mb-1"
          min-width="36px"
          width="36px"
          @click="refresh"
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {mapGetters} from 'vuex'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'

export default {
  mixins: [blockAutoUpdate, blockVisibility],
  data:() => ({
    common_payment_sum_byn: null,
    common_payment_sum_eur: null,
    block_id: "payment-sum-details"
  }),
  computed:{
    ...mapGetters({
      selected: 'ensuring/getSelected',
      requests:'ensuring/getRequests'
    }),
    isRegistered(){
      return this.selected?.registration_details?.reg_num_a
    },
    isDisabled(){
      return this.selected?.declaration_type != null && this.selected?.declaration_type != 'PTD'
    }
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
      },
      deep:true
    }
  },
  methods:{
    async refresh(){
      await Promise.all(this.requests)
      const {id,declaration_type} = this.selected
      this.$store.dispatch('ensuring/refreshPayments', {id,type: declaration_type}).then(() => {
        this.$snackbar({text:'Обновлено [Сумма гарантии]', color:'green',top:false})
      }).catch(() => this.$error())
    },
    setFields() {
      const { common_payment_sum_byn, common_payment_sum_eur } = this.selected || {};
      this.common_payment_sum_byn = common_payment_sum_byn
      this.common_payment_sum_eur = common_payment_sum_eur
    },
    uploadData() {
      const about_payments = this.convertEmptyStringsToNull({
        declaration_id: this.selected?.id,
        common_payment_sum_byn: this.common_payment_sum_byn,
        common_payment_sum_eur: this.common_payment_sum_eur
      });

      return this.$store
        .dispatch("ensuring/uploadBlockData", {
          name: "about_payments",
          value: about_payments,
        });
    },
  }
}
</script>
