<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="closeWindow"
      width="800px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <v-btn
          color="error"
          small
          @click="closeWindow"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="teal"
      />
      <v-list>
        <v-list-item>
          <span class="mx-2 font-weight-bold">Дата выставления: </span>
          {{ formatDate(statusMessage.dateIssued) }}
        </v-list-item>
        <v-list-item>
          <span class="mx-2 font-weight-bold">Срок исполнения: </span>
          {{ formatDate(statusMessage.expirationDate) }}
        </v-list-item>
        <v-list-item>
          <span class="mx-2 font-weight-bold">Номер требования: </span>
          {{ statusMessage.requirementID }}
        </v-list-item>
        <v-list-item>
          <span class="mx-2 font-weight-bold">Текст: </span>
          {{ statusMessage.requirementText }}
        </v-list-item>
      </v-list>
      <div class="white pa-5 rounded-0">
        <v-file-input
          v-model="file"
          :rules="fileRules"
          :accept="'.pdf, .tif'"
          dense
          outlined
          color="#32a1af"
          solo
          flat
          hide-details="auto"
          show-size
          placeholder="Выберите файл"
        />
        <div class="d-flex justify-end mt-4">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="#5CB7B1"
            elevation="0"
            darks
            @click="submit"
          >
            Отправить
          </v-btn>
        </div>
      </div>
      <component
        :is="activeComponent"
        :show.sync="showChild"
        :params="activeComponentParams"
      />
    </v-card>
  </v-overlay>
</template>
<script>


import formatDate from '@/mixins/format-date.js'
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";
import {getMessageFromNcesResponse, getSignedDocument} from "@/helpers/documents";

export default {
  mixins: [modalWindowMixin, formatDate],
  props:{
    statusMessage: {
      type: Object,
      required: true
    },
    itemId:{
      required:true,
      type:Number
    },
  },
  data: () => ({
    loading: false,
    activeComponent: null,
    activeComponentParams: {
      text: "",
    },
    showChild: false,
    fileRules: [
      value => !!value || 'Поле должно быть заполнено',
      value => !value || value.size < 15728640 ||
          'Файл не может превышать размер 15 MB!'
    ],
    file: [],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    }),
    userId() {
      return this.user?.user?.id
    },
  },
  methods: {
    getSignedDocument,

    closeWindow() {
      this.close();
      this.$emit("onMenuInput", false);
    },
    submit() {
      this.loading = true
      const formData = new FormData()
      formData.set('declarationId', this.itemId)
      formData.set(this.statusMessage.requirementID, this.file)

      let fileGuid;
      this.getXml(formData)
        .then(({xml, guid}) => {
          fileGuid = guid
          return Promise.resolve({
            xml, guid
          })
        })
        .then(this.getSignedDocument)
        .then((xml) => {
          return Promise.resolve({
            fileGuid,
            xml
          })
        })
        .then(this.sendToDatabase)
        .then(this.fetchDocument)
        .catch(() => {})
        .finally(() => this.loading = false)
    },
    getXml(formData) {
      return this.$store.dispatch('dt/getBD44RequirementXml', formData)
        .then(res => res.data)
        .catch(err => {
          this.showXsdControlResponse(err.response)
          throw err
        })
    },
    sendToDatabase({fileGuid, xml}) {
      return this.$store.dispatch('dt/sendBD44ToNces', {
        userId: this.userId,
        payload: {
          xml,
          fileGuid,
          declarationId: this.itemId
        }
      })
        .then((res) => {
          this.$success("Отправлено")
          this.closeWindow();
          return res
        }).catch((err) => {
          const message = getMessageFromNcesResponse(err)
          this.$error(message)
          throw err
        })
    },
    fetchDocument(){
      return this.$store.dispatch('dt/getDocumentById', this.itemId)
    },
    showXsdControlResponse({data = ""}) {
      this.activeComponentParams.text = data
      this.activeComponent = () => import("@/components/documents/xsd-control-response.vue")
      this.showChild = true
    }
  }
}
</script>
