<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>{{ title }}</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <slot name="header" />
        <v-data-table
          fixed-header
          height="540px"
          class="rounded-0"
          :item-class="getItemClass"
          disable-sort
          :headers="headers"
          :items="wares"
          :footer-props="{
            'items-per-page-options':[10,50,100, -1],
          }"
          @click:row="bufferWare"
          @mouseover.native="bufferWareMouseMove"
        >
          <template #[`item.tn_ved`]="{item}">
            {{ item.tn_ved }} <span class="teal--text">{{ item.add_tn_ved }}</span>
          </template>
          <template #[`item.release_date`]="{item}">
            {{ formatDate(item.release_date) }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>

import closeOnEscape from "@/mixins/close-on-escape.mixin"
import formatDate from '@/mixins/format-date.js'
import bufferMixin from "@/mixins/buffer.mixin";

export default {
  mixins:[closeOnEscape, formatDate, bufferMixin],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:true,
    },
    headers:{
      type:Array,
      required:true,
    },
    wares:{
      type:Array,
      required:true,
    },
    title:{
      type:String,
      required:true,
    }
  },
  data: () => ({
    items: [],
  }),
  watch: {
    wares: {
      handler(nv) {
        this.items = nv;
      },
      immediate: true,
    },
  },
  methods:{
    getItemClass(item){
      const classList = `item-id-${item.id} `
      if(this.bufferWaresIds.includes(item.id)){
        return classList + 'teal lighten-4'
      }
      return classList
    },
    close(e){
      if(e && e.target.classList.contains('v-list-item__title')) return;
      this.$emit('update:show', false)
    },
  }
}
</script>
<style scoped>
.goods__param{
  margin: 0 10px;
}
</style>
