<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template :tabs="tabs" />
    <dt-toolbox />
    <dt-main-info />
    <v-container fluid>
      <v-row>
        <declarant />
        <sender
          :contagent-queue="senderQueue"
          @changeContagentQueue="changeFinancialQueue"
        />
        <recipient />
        <financial
          :contagent-queue="financialQueue"
          @changeContagentQueue="changeSenderQueue"
        />
      </v-row>
    </v-container>
    <dt-place-date />
    <dt-contract-info />
    <dt-transport />
    <dt-location />
    <documents />
    <goods-character v-if="!pinned.includes('goods-character')" />
    <dt-goods />

    <div class="ml-3 mr-3">
      <v-row>
        <deferred-payment />
        <warranty />
      </v-row>
    </div>
    
    <dt-including-cost />
    <dt-graph-b-container />

    <shared-buffer />
    <dt-copy-field-modal />
    <copy-field-to-all-modal />
    <catalogs />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import DtToolbox from '@/components/dt/dt-toolbox.vue'
import DtMainInfo from '@/components/dt/dt-main-info.vue'
import Declarant from '@/components/dt/contragents/declarant.vue'
import Sender from '@/components/dt/contragents/sender.vue'
import DtPlaceDate from '@/components/dt/dt-place-date.vue'
import DtContractInfo from '@/components/dt/dt-contract-info.vue'
import DtTransport from '@/components/dt/transport/transport.vue'
import DtLocation from '@/components/dt/location/dt-location.vue'
import DtIncludingCost from '@/components/dt/including-cost/including-cost-list.vue'
import Documents from '@/components/dt/documents/documents.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue"
import GoodsCharacter from '@/components/dt/goods-character.vue'
import DtGoods from '@/components/dt/dt-goods.vue'
import Financial from '@/components/dt/contragents/financial.vue'
import Recipient from '@/components/dt/contragents/recipient.vue'
import offsetMixin from '@/mixins/offset.mixin.js'
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import {setDeclarationTitle} from "@/helpers/text";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import DtCopyFieldModal from "@/components/dt/goods/dt-copy-field-modal.vue";
import DtGraphBContainer from "@/components/dt/graph-b/graphbcontainer.vue"
import Warranty from '../../components/dt/warranty/warranty.vue';
import DeferredPayment from '../../components/dt/deferred-payment/deferred-payment.vue';

export default {
  components: {
    DtCopyFieldModal,
    SideMenu,
    DistinctDivisionNotification,
    CopyFieldToAllModal,
    PinnedTemplate,
    DtToolbox,
    DtMainInfo,
    Declarant,
    Sender,
    DtPlaceDate,
    DtContractInfo,
    DtTransport,
    DtLocation,
    DtIncludingCost,
    Documents,
    Catalogs,
    GoodsCharacter,
    DtGoods,
    Financial,
    Recipient,
    DtGraphBContainer,
    Warranty,
    DeferredPayment,
    SharedBuffer
  },
  mixins: [offsetMixin],
  provide: {
    rootNamespace: 'dt'
  },
  data: () => ({
    menuItems: [
      {
        name: "Основная информация",
        id: "dt-main-info"
      },
      {
        name: "Декларант",
        id: "dt-declarant"
      },
      {
        name: "Получатель",
        id: "dt-recipient"
      },
      {
        name: "Отправитель",
        id: "dt-sender"
      },
      {
        name: "Плательщик",
        id: "dt-payer"
      },
      {
        name: "Место и дата",
        id: "dt-place-date"
      },
      {
        name: "Сведения из договора",
        id: "dt-deal-details"
      },
      {
        name: "Транспорт",
        id: "dt-about-transport"
      },
      {
        name: "Местонахождение товаров",
        id: "dt-ware-location"
      },
      {
        name: "Документы",
        id: "dt-documents"
      },
      {
        name: "Общие характеристики товара",
        id: "dt-goods-character"
      },
      {
        name: "Товары",
        id: "dt-goods-block",
        component: WaresScroll,
      },
      {
        name: "Включение в стоимость",
        id: 'dt-including-cost'
      }
    ],
    tabs: [
      {
        name: 'documents',
        component: () => import('@/components/dt/documents/documents.vue')
      },
      {
        name: 'goods-character',
        component: () => import('@/components/dt/goods-character.vue')
      },
      {
        name: 'controls',
        component: () => import('@/components/dt/controls/common-errors.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/dt/controls/payment-errors.vue'),
      }
    ],
    senderQueue: false,
    financialQueue: false
  }),
  computed: {
    ...mapGetters({
      selected: 'dt/getSelected',
      pinned: "ui/getPinned",
    }),
  },
  watch: {
    selected: {
      handler(dtDeclaration) {
        const {remark, out_number: outNumber} = dtDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "ДТ"
        })
        this.changeType();
      },
      deep: true
    }
  },
  created() {
    this.setExtraTnvedOptions()
    this.fetchPaymentTypes()
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('dt/fetchNsiAeos')
    this.$store.dispatch('dt/fetchNsiTransportMarks')
    const id = this.$route.params.id
    this.$store.dispatch("dt/getDocumentById", id).catch(err => {
      if (err.response.status === 403) {
        this.$router.push('/403')
      }
    });
  },
  methods: {
    fetchPaymentTypes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then((res) => {
        this.$store.commit('dt/SET_PAYMENT_TYPES', res.data)
      })
    },
    changeType() {
      if (this.selected.type_declaration) {
        this.declarant_type = this.selected.type_declaration.customs_procedure
      }
    },
    changeFinancialQueue(e) {
      this.financialQueue = e
    },
    changeSenderQueue(e) {
      this.senderQueue = e
    },
    setExtraTnvedOptions() {
      this.$store.dispatch('catalogs/fetchExtraTnvedOptions').then((res) => {
        const {names_11, names_12, names_13, names_14} = res.data
        this.$store.commit('dt/SET_EXTRA_TNVED_OPTIONS', {
          firstOptions: names_11,
          secondOptions: names_12,
          thirdOptions: names_13,
          fourthOptions: names_14
        })
      })
    }
  },
}
</script>
