<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="mr-2"
        min-width="36px"
        max-width="36px"
        v-on="on"
      >
        <v-icon> mdi-content-duplicate </v-icon>
      </v-btn>
    </template>
    <v-treeview
      :items="list"
      item-key="id"
      hoverable
      class="treeview"
    >
      <template #prepend="{ item }">
        <div
          class="d-flex align-center"
          @click.stop.prevent="item.action(item.id, item.doc)"
        >
          <v-icon class="mr-2">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </div>
      </template>
    </v-treeview>
    <!-- Старый функционал не удаляю (начало) -->
    <!-- <v-list>
      <v-list-item @click="createDTS">
        <v-icon>mdi-plus</v-icon> Создать ДТС-1
      </v-list-item>
      <v-divider v-if="list.length" />
      <v-list-item
        v-for="(item, index) in list"
        :key="index"
        @click="openDts(item.id)"
      >
        <v-icon>mdi-open-in-new</v-icon> <span class="px-1">{{ item.id }}</span> <span v-if="item.out_number">«{{ item.out_number }}»</span>
      </v-list-item>
    </v-list> -->
    <!-- конец -->
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { DTS, SD, ZVT, KDT, RS } from "@/http-routes";

export default {
  data() {
    return {
      list: [
        {
          name: "Создать ДТС-1",
          type: "DTS1",
          id: "1",
          icon: "mdi-plus",
          doc: "createDts",
          action: (id, doc) => this.createDocument(id, doc),
        },
        {
          name: "Создать ДТС-2",
          type: "DTS2",
          id: "2",
          icon: "mdi-plus",
          doc: "createDts",
          action: (id, doc) => this.createDocument(id, doc),
        },
        {
          name: "Создать СД ЭК",
          type: "SD",
          id: "3",
          icon: "mdi-plus",
          doc: "createSd",
          action: (id, doc) => this.createDocument(id, doc),
        },
        {
          name: "Создать ЗВТ",
          type: "ZVT",
          id: "4",
          icon: "mdi-plus",
          doc: "createZvt",
          action: (id, doc) => this.createDocument(id, doc),
        },
        {
          name: "Создать КДТ",
          type: "KDT",
          id: "5",
          icon: "mdi-plus",
          doc: "createKdt",
          action: (id, doc) => this.createDocument(id, doc),
        },
        {
          name: "Создать РС",
          type: "RS",
          id: "6",
          icon: "mdi-plus",
          doc: "createUs",
          action: (id, doc) => this.createDocument(id, doc),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
    }),
  },
  watch: {
    "selected.id": {
      handler() {
        this.getAllListByDeclarationId();
      },
    },
  },
  methods: {
    getAllListByDeclarationId() {
      return Promise.all([
        axios.get(`${DTS}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
        axios.get(`${SD}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
        axios.get(`${ZVT}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
        axios.get(`${KDT}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
        axios.get(`${RS}declaration/getByDeclarationId?declarationId=${this.selected.id}`),
      ]).then(([{ data: dts }, { data: sd }, { data: zvt }, { data: kdt }, { data: rs }]) => {
        this.addedChildrenToList({ payload: dts });
        this.addedChildrenToList({payload: this.addedDeclarationType(sd, "SD")});
        this.addedChildrenToList({payload: this.addedDeclarationType(zvt, "ZVT")});
        this.addedChildrenToList({payload: this.addedDeclarationType(kdt, "KDT")});
        this.addedChildrenToList({payload: this.addedDeclarationType(rs, "RS")});
      });
    },
    addedChildrenToList({ payload }) {
      this.list = this.list.map((item) => {
        const children = payload.filter(
          (elem) => elem.declaration_type === item.type
        );
        if (children.length !== 0) {
          return {
            ...item,
            children: this.formationDoc(children, item.doc),
          };
        } else return item;
      });
    },
    formationDoc(children, doc) {
      return children
        .map((item) => {
          return {
            ...item,
            doc: doc,
            name: `${item.id} «${item.out_number}»`,
            icon: "mdi-open-in-new",
            action: (id, doc) => this.openDoc(id, doc),
          };
        })
        .sort((a, b) => b.id - a.id);
    },
    addedDeclarationType(array, type) {
      return array.map((item) => {
        return {
          ...item,
          declaration_type: type,
        };
      });
    },
    openDoc(id, doc) {
      let route;
      switch (doc) {
      case "createDts":
        route = this.$router.resolve({
          path: `/dts/${id}`,
        });
        break;
      case "createSd":
        route = this.$router.resolve({
          path: `/statistics/${id}`,
        });
        break;
      case "createZvt":
        route = this.$router.resolve({
          path: `/zvt/${id}`,
        });
        break;
      case "createKdt":
        route = this.$router.resolve({
          path: `/kdt/${id}`,
        });
        break;
      case "createUs":
        route = this.$router.resolve({
          path: `/rs/${id}`,
        });
        break;
      }
      window.open(route.href, "_blank");
    },
    createDocument(id, doc) {
      const dtsN = ["createDts"].includes(doc) ? id : null;
      this.$store
        .dispatch("dt/createNeedfulDocument", { document: doc, dtsN })
        .then(({ data }) => this.openDoc(data, doc))
        .then(() => this.getAllListByDeclarationId())
        .catch((err) => this.$error(err.response.data));
    },
  },
};
</script>

<style scoped>
.treeview {
  background-color: #fff;
  cursor: pointer;
}
</style>
