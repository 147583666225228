import axios from "axios";
import {NOTIFICATOR} from "@/http-routes";

const getDefaultState = () => ({
  notViewedAmount: 0,
  groups: null,
  documentStatuses: []
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_NOT_VIEWED_AMOUNT(state, value) {
      state.notViewedAmount = value
    },
    SET_GROUPS(state, value) {
      state.groups = value
    },
    SET_DOCUMENT_STATUSES(state, value) {
      state.documentStatuses = value;
    },
  },
  actions: {
    fetchNewNotificationsAmount({rootState, commit}) {
      const id = rootState.auth.user.user.id
      return axios.get(`${NOTIFICATOR}countNotViewedNotificationsForUser?userId=${id}`)
        .then(res => {
          commit('SET_NOT_VIEWED_AMOUNT', res.data)
          return res
        })
    },
    fetchNewNotifications({rootState, commit}) {
      const id = rootState.auth.user.user.id
      return axios.get(`${NOTIFICATOR}getAllNotificationsForUser?userId=${id}`)
        .then(res => {
          commit('SET_GROUPS', res.data)
          return res
        })
    },
    setAllViewed({rootState, commit}) {
      const id = rootState.auth.user.user.id
      return axios.post(`${NOTIFICATOR}setViewedOnNotificationsForUser?userId=${id}`)
        .then(res => {
          commit('SET_NOT_VIEWED_AMOUNT', 0)
          return res
        })
    }
  },
  getters: {
    getNotViewedAmount: s => s.notViewedAmount,
    getGroups: s => s.groups,
    getDocumentsStatuses: s => s.documentStatuses,
  }
}
